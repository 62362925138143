import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "pafos_maslac" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>This settlement was brokered successfully by the alternative dispute resolution professionals of Catenaccio Sports Agency.</p>
          <p>The Agency was instructed by FC Pafos to find a settlement of the dispute with the player after the FIFA DRC held against the club. Once instructed, the Agency found an appropriate and workable way to establish constructive negotiations with the player and his advisers. The Agency applied effectively alternative dispute resolution methods tailored to operate specifically against the shadow of law which was the basis of the player's negotiating position. The settlement agreement was reached in a way which made both conflicting parties content so that the mutual claims were withdrawn and settled.</p>
        </div>
      </div>
    )
  }
}